import http from './request.js'

// 获取站点信息接口
export const getWebsite = (token, params = {}) => {
  return http(token).get(`/system/api/website/view?domain=${params.domain}`)
}

// 获取站点信息接口
export const getAuthWebsite = (token, params = {}) => {
  return http(token).get(`/system/auth/website/view?domain=${params.domain}`)
}

// 获取ali上传配置
export const getUploadConfig = (params) => {
  return http().post('/system/auth/upload/aliyun', params)
}

// 获取polyv上传sign
export const getPolyvVideoSign = (params) => {
  return http().post('/system/auth/upload/polyv', params)
}

// 枚举
export const enumList = (enumName) => {
  return http().get(`/system/api/enum/list?enumName=${enumName}`)
}

export const getIP = () => {
  return http().get(`/system/api/website/ip`)
}
/**
 * 服务端上传图片接口
 * @param data
 */
export function uploadPic(data, cb) {
  const config = {
    onUploadProgress: progressEvent => {
      const videoUploadPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
      // 计算上传进度
      if (cb) {
        cb(videoUploadPercent)
      }
    }
  }
  return http().post('/system/auth/upload/pic', data, config)
}

/**
 * 服务端上传文档接口
 * @param data
 */
export function uploadDoc(data, cb) {
  const config = {
    onUploadProgress: progressEvent => {
      const videoUploadPercent = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
      // 计算上传进度
      if (cb) {
        cb(videoUploadPercent)
      }
    }
  }
  return http().post('/system/auth/upload/pub/doc', data, config)
}

/**
 * 企业注册
 * @param data
 * @returns {*}
 */
export function enterpriseInfoAdd(data) {
  return http().post('/user/auth/enterprise/info/add', data)
}

// 企业用户信息修改 （头像，用户名称）
export function editUpdateEnterpriseUserInfo(data) {
  return http().put('/user/auth/enterprise/user/info/update', data)
}

// 获取图片验证码
export function getCodeImg() {
  return http().get('/user/api/user/info/get/image/verification/code')
}

/**
 * 部门信息列表
 * @param data
 */
export function departmentInfoList(data) {
  return http().post('/user/auth/department/info/list', data)
}

/**
 * 子部门信息列出
 * @param data
 */
export function departmentInfoChildrenList(data) {
  return http().post('/user/auth/department/info/children/list', data)
}

/**
 * 用户与部门关联信息列表
 * @param data
 */
export function userDepartmentList(data) {
  return http().post('/user/auth/user/department/list', data)
}

/**
 * 企业用户信息列表
 * @param data
 */
export function enterpriseUserList(data) {
  return http().post('/user/auth/enterprise/user/info/list', data)
}

// 获取当前企业水印配置
export function enterpriseWatermarkConfig() {
  return http().get('/user/auth/enterprise/info/current/view/config')
}

export function publicKey() {
  return http().get('/system/api/sys/config/get/public/key')
}
