const __DEFUALT__COLOR = "#409EFF"
const version = require('element-ui/package.json').version

class Theme {
  constructor() {
    const color = this._getLocalTheme() || __DEFUALT__COLOR;
    const oldVal = this._getLocalTheme() || __DEFUALT__COLOR;
    this.changeTheme(color, oldVal);
    this.chalk = ''
  }
  async changeTheme($color, oldVal) {
    const themeCluster = this._getThemeCluster($color.replace('#', ''))
    const originalCluster = this._getThemeCluster(oldVal.replace('#', ''))
    const getHandler = (id) => {
      return () => {
        const originalCluster = this._getThemeCluster(__DEFUALT__COLOR.replace('#', ''))
        const newStyle = this._updateStyle(this.chalk, originalCluster, themeCluster)

        let styleTag = document.getElementById(id)
        if (!styleTag) {
          styleTag = document.createElement('style')
          styleTag.setAttribute('id', id)
          document.head.appendChild(styleTag)
        }
        styleTag.innerText = newStyle
      }
    }
    if (!this.chalk) {
      const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`
      await this._getCSSString(url, 'chalk')
    }
    const chalkHandler = getHandler('chalk-style')
    chalkHandler()
    const styles = [].slice.call(document.querySelectorAll('style'))
      .filter(style => {
        const text = style.innerText
        return new RegExp(oldVal, 'i').test(text) && !/Chalk Variables/.test(text)
      })
    styles.forEach(style => {
      const { innerText } = style
      if (typeof innerText !== 'string') return
      style.innerText = this._updateStyle(innerText, originalCluster, themeCluster)
    })
    this._setLocalTheme("--primary-color", $color || '#409EFF')
  }
  _getLocalTheme(key = "--primary-color") {
    return key && localStorage.getItem(key);
  }
  _setLocalTheme(key, value) {
    localStorage[key] = value;
  }
  _getThemeCluster(theme) {
    const tintColor = (color, tint) => {
      let red = parseInt(color.slice(0, 2), 16)
      let green = parseInt(color.slice(2, 4), 16)
      let blue = parseInt(color.slice(4, 6), 16)

      if (tint === 0) { // when primary color is in its rgb space
        return [red, green, blue].join(',')
      } else {
        red += Math.round(tint * (255 - red))
        green += Math.round(tint * (255 - green))
        blue += Math.round(tint * (255 - blue))

        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)

        return `#${red}${green}${blue}`
      }
    }

    const shadeColor = (color, shade) => {
      let red = parseInt(color.slice(0, 2), 16)
      let green = parseInt(color.slice(2, 4), 16)
      let blue = parseInt(color.slice(4, 6), 16)

      red = Math.round((1 - shade) * red)
      green = Math.round((1 - shade) * green)
      blue = Math.round((1 - shade) * blue)

      red = red.toString(16)
      green = green.toString(16)
      blue = blue.toString(16)

      return `#${red}${green}${blue}`
    }

    const clusters = [theme]
    for (let i = 0; i <= 9; i++) {
      clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
    }
    clusters.push(shadeColor(theme, 0.1))
    return clusters
  }
  _updateStyle(style, oldCluster, newCluster) {
    let newStyle = style
    oldCluster.forEach((color, index) => {
      newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index])
    })
    return newStyle
  }
  _getCSSString(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this.chalk = xhr.responseText.replace(/@font-face{[^}]+}/, '')
          resolve()
        }
      }
      xhr.open('GET', url)
      xhr.send()
    })
  }
}

export default Theme
