import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _84b8db6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _6ced15e4 = () => interopDefault(import('../pages/agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _092b49b2 = () => interopDefault(import('../pages/appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _7b681c34 = () => interopDefault(import('../pages/certificate/index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _377416f4 = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "pages/community/index" */))
const _cc199152 = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _b1a18a50 = () => interopDefault(import('../pages/development/index.vue' /* webpackChunkName: "pages/development/index" */))
const _4467b9ac = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _093bb1fa = () => interopDefault(import('../pages/forget.vue' /* webpackChunkName: "pages/forget" */))
const _531c3338 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _361836db = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _ecbfa866 = () => interopDefault(import('../pages/lecturer/index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _0e0f5cc3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _22429ae5 = () => interopDefault(import('../pages/permission.vue' /* webpackChunkName: "pages/permission" */))
const _27e63d8e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2fa04e08 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _ba14886c = () => interopDefault(import('../pages/tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _16b3d2ec = () => interopDefault(import('../pages/watch/index.vue' /* webpackChunkName: "pages/watch/index" */))
const _7b123d95 = () => interopDefault(import('../pages/development/estimate/index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _5b69e638 = () => interopDefault(import('../pages/development/qualification/index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _59159b92 = () => interopDefault(import('../pages/development/study/index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _3476a636 = () => interopDefault(import('../pages/home/rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _7b141e1e = () => interopDefault(import('../pages/integral/mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _03aa486b = () => interopDefault(import('../pages/investigate/view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _39d0a78f = () => interopDefault(import('../pages/watch/catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _2a556cf1 = () => interopDefault(import('../pages/watch/course.vue' /* webpackChunkName: "pages/watch/course" */))
const _4704f2de = () => interopDefault(import('../pages/watch/practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _449cd4db = () => interopDefault(import('../pages/watch/task.vue' /* webpackChunkName: "pages/watch/task" */))
const _371b3b8e = () => interopDefault(import('../pages/account/exam/detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _cbdf25e6 = () => interopDefault(import('../pages/account/exam/examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _0c3869ac = () => interopDefault(import('../pages/account/exam/examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _322c24ef = () => interopDefault(import('../pages/account/plan/detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _bc6cd9ce = () => interopDefault(import('../pages/development/estimate/batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _1748e389 = () => interopDefault(import('../pages/development/estimate/evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _306b9801 = () => interopDefault(import('../pages/development/estimate/report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _3c7f1d96 = () => interopDefault(import('../pages/account/plan/task/add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _5c025a6d = () => interopDefault(import('../pages/account/plan/task/detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _ee6cde38 = () => interopDefault(import('../pages/knowledge/offcourse/components/AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _5f122182 = () => interopDefault(import('../pages/account/exam/_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _768fd8e8 = () => interopDefault(import('../pages/account/task/_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _3da81674 = () => interopDefault(import('../pages/integral/goods/_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _7de41fbe = () => interopDefault(import('../pages/investigate/analyze/_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _1f08d8e6 = () => interopDefault(import('../pages/knowledge/course/_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _c8f6c804 = () => interopDefault(import('../pages/knowledge/offcourse/_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _2cc75be6 = () => interopDefault(import('../pages/knowledge/project/_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _26d3dcb6 = () => interopDefault(import('../pages/lecturer/view/_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _282102ad = () => interopDefault(import('../pages/live/watch/_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _016fba27 = () => interopDefault(import('../pages/redirect/_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _84b8db6c,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _6ced15e4,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _092b49b2,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _7b681c34,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _377416f4,
    name: "community___zh"
  }, {
    path: "/company",
    component: _cc199152,
    name: "company___zh"
  }, {
    path: "/development",
    component: _b1a18a50,
    name: "development___zh"
  }, {
    path: "/en",
    component: _4467b9ac,
    name: "index___en"
  }, {
    path: "/forget",
    component: _093bb1fa,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _531c3338,
    name: "home___zh"
  }, {
    path: "/ja",
    component: _4467b9ac,
    name: "index___ja"
  }, {
    path: "/knowledge",
    component: _361836db,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _ecbfa866,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _0e0f5cc3,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _22429ae5,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _27e63d8e,
    children: [{
      path: "",
      component: _2fa04e08,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _ba14886c,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _16b3d2ec,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _7b123d95,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _5b69e638,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _59159b92,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _84b8db6c,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _6ced15e4,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _092b49b2,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _7b681c34,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _377416f4,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _cc199152,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _b1a18a50,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _093bb1fa,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _531c3338,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _361836db,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _ecbfa866,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _0e0f5cc3,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _22429ae5,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _27e63d8e,
    children: [{
      path: "",
      component: _2fa04e08,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _ba14886c,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _16b3d2ec,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _3476a636,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _7b141e1e,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _03aa486b,
    name: "investigate-view___zh"
  }, {
    path: "/ja/account",
    component: _84b8db6c,
    name: "account___ja"
  }, {
    path: "/ja/agreement",
    component: _6ced15e4,
    name: "agreement___ja"
  }, {
    path: "/ja/appCompany",
    component: _092b49b2,
    name: "appCompany___ja"
  }, {
    path: "/ja/certificate",
    component: _7b681c34,
    name: "certificate___ja"
  }, {
    path: "/ja/community",
    component: _377416f4,
    name: "community___ja"
  }, {
    path: "/ja/company",
    component: _cc199152,
    name: "company___ja"
  }, {
    path: "/ja/development",
    component: _b1a18a50,
    name: "development___ja"
  }, {
    path: "/ja/forget",
    component: _093bb1fa,
    name: "forget___ja"
  }, {
    path: "/ja/home",
    component: _531c3338,
    name: "home___ja"
  }, {
    path: "/ja/knowledge",
    component: _361836db,
    name: "knowledge___ja"
  }, {
    path: "/ja/lecturer",
    component: _ecbfa866,
    name: "lecturer___ja"
  }, {
    path: "/ja/login",
    component: _0e0f5cc3,
    name: "login___ja"
  }, {
    path: "/ja/permission",
    component: _22429ae5,
    name: "permission___ja"
  }, {
    path: "/ja/register",
    component: _27e63d8e,
    children: [{
      path: "",
      component: _2fa04e08,
      name: "register___ja"
    }]
  }, {
    path: "/ja/tpl",
    component: _ba14886c,
    name: "tpl___ja"
  }, {
    path: "/ja/watch",
    component: _16b3d2ec,
    name: "watch___ja"
  }, {
    path: "/watch/catePanel",
    component: _39d0a78f,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _2a556cf1,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _4704f2de,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _449cd4db,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _371b3b8e,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _cbdf25e6,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _0c3869ac,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/plan/detail",
    component: _322c24ef,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _bc6cd9ce,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _1748e389,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _306b9801,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _7b123d95,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _5b69e638,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _59159b92,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _3476a636,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _7b141e1e,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _03aa486b,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _39d0a78f,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _2a556cf1,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _4704f2de,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _449cd4db,
    name: "watch-task___en"
  }, {
    path: "/ja/development/estimate",
    component: _7b123d95,
    name: "development-estimate___ja"
  }, {
    path: "/ja/development/qualification",
    component: _5b69e638,
    name: "development-qualification___ja"
  }, {
    path: "/ja/development/study",
    component: _59159b92,
    name: "development-study___ja"
  }, {
    path: "/ja/home/rank",
    component: _3476a636,
    name: "home-rank___ja"
  }, {
    path: "/ja/integral/mall",
    component: _7b141e1e,
    name: "integral-mall___ja"
  }, {
    path: "/ja/investigate/view",
    component: _03aa486b,
    name: "investigate-view___ja"
  }, {
    path: "/ja/watch/catePanel",
    component: _39d0a78f,
    name: "watch-catePanel___ja"
  }, {
    path: "/ja/watch/course",
    component: _2a556cf1,
    name: "watch-course___ja"
  }, {
    path: "/ja/watch/practice",
    component: _4704f2de,
    name: "watch-practice___ja"
  }, {
    path: "/ja/watch/task",
    component: _449cd4db,
    name: "watch-task___ja"
  }, {
    path: "/account/plan/task/add",
    component: _3c7f1d96,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _5c025a6d,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _371b3b8e,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _cbdf25e6,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _0c3869ac,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/plan/detail",
    component: _322c24ef,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _bc6cd9ce,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _1748e389,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _306b9801,
    name: "development-estimate-report___en"
  }, {
    path: "/ja/account/exam/detail",
    component: _371b3b8e,
    name: "account-exam-detail___ja"
  }, {
    path: "/ja/account/exam/examQuestion",
    component: _cbdf25e6,
    name: "account-exam-examQuestion___ja"
  }, {
    path: "/ja/account/exam/examSuccess",
    component: _0c3869ac,
    name: "account-exam-examSuccess___ja"
  }, {
    path: "/ja/account/plan/detail",
    component: _322c24ef,
    name: "account-plan-detail___ja"
  }, {
    path: "/ja/development/estimate/batchEvaluation",
    component: _bc6cd9ce,
    name: "development-estimate-batchEvaluation___ja"
  }, {
    path: "/ja/development/estimate/evaluation",
    component: _1748e389,
    name: "development-estimate-evaluation___ja"
  }, {
    path: "/ja/development/estimate/report",
    component: _306b9801,
    name: "development-estimate-report___ja"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _ee6cde38,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _3c7f1d96,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _5c025a6d,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _ee6cde38,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/ja/account/plan/task/add",
    component: _3c7f1d96,
    name: "account-plan-task-add___ja"
  }, {
    path: "/ja/account/plan/task/detail",
    component: _5c025a6d,
    name: "account-plan-task-detail___ja"
  }, {
    path: "/ja/knowledge/offcourse/components/AssessAttachment",
    component: _ee6cde38,
    name: "knowledge-offcourse-components-AssessAttachment___ja"
  }, {
    path: "/",
    component: _4467b9ac,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _5f122182,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _768fd8e8,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _3da81674,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _7de41fbe,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _1f08d8e6,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _c8f6c804,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _2cc75be6,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _26d3dcb6,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _282102ad,
    name: "live-watch-watch___en"
  }, {
    path: "/ja/account/exam/:id?",
    component: _5f122182,
    name: "account-exam-id___ja"
  }, {
    path: "/ja/account/task/:id?",
    component: _768fd8e8,
    name: "account-task-id___ja"
  }, {
    path: "/ja/integral/goods/:id?",
    component: _3da81674,
    name: "integral-goods-id___ja"
  }, {
    path: "/ja/investigate/analyze/:id?",
    component: _7de41fbe,
    name: "investigate-analyze-id___ja"
  }, {
    path: "/ja/knowledge/course/:id?",
    component: _1f08d8e6,
    name: "knowledge-course-id___ja"
  }, {
    path: "/ja/knowledge/offcourse/:id?",
    component: _c8f6c804,
    name: "knowledge-offcourse-id___ja"
  }, {
    path: "/ja/knowledge/project/:id?",
    component: _2cc75be6,
    name: "knowledge-project-id___ja"
  }, {
    path: "/ja/lecturer/view/:id?",
    component: _26d3dcb6,
    name: "lecturer-view-id___ja"
  }, {
    path: "/ja/live/watch/:watch?",
    component: _282102ad,
    name: "live-watch-watch___ja"
  }, {
    path: "/account/exam/:id?",
    component: _5f122182,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _768fd8e8,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _016fba27,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _3da81674,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _7de41fbe,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/ja/redirect/:path?",
    component: _016fba27,
    name: "redirect-path___ja"
  }, {
    path: "/knowledge/course/:id?",
    component: _1f08d8e6,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _c8f6c804,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _2cc75be6,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _26d3dcb6,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _282102ad,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _016fba27,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
