import cookie from '../utils/cookies'
import { clearSession, removeStore, setStore } from '@/utils/storage'
import config from '~/config'

const tokenName = config.tokenName

export default {
  TOGGLE_IMAGEVERIGICATION: (state, isShow) => {
    state.loginImageVerification = isShow
  },
  // 记录token
  SET_TOKEN: (state, token) => {
    state.tokenInfo = token
    cookie.setInClient({ key: tokenName, val: token })
    // setStore('tokenInfo', info.info)
  },
  // 获取token
  GET_TOKEN: (state) => {
    const token = cookie.getInClient(tokenName)
    state.tokenInfo = token
  },
  // 服务端获取token
  GET_TOKEN_SERVER: (state, req) => {
    const cook = cookie.getInServer(req)
    state.tokenInfo = cook[tokenName]
  },
  // 记录用户信息
  SET_USER: (state, data) => {
    if (data) {
      data.token = state.tokenInfo
      state.userInfo = data
      // console.log(JSON.stringify(state), '=====geUserInfo')
      if (process.client) {
        setStore('RcUserInfo', data)
      }
    }
    // Vue.set(state.users, id, user || false) /* false means user not found */
  },
  SET_LANG(state, lang) {
    state.lang = lang
    cookie.setInClient({ key: 'lang', val: lang })
    if (lang === 'ja') {
      document.documentElement.style.setProperty('--font-family', '"メイリオ",Meiryo,"游ゴシック","Yu Gothic",sans-serif');
    } else {
      document.documentElement.style.setProperty('--font-family', 'Microsoft YaHei, sans-serif');
    }

  },
  // 退出登录
  SIGN_OUT: (state) => {
    state.userInfo = ''
    state.tokenInfo = ''
    cookie.delInClient(tokenName)
    // cookie.clear()
    clearSession()
    removeStore('RcUserInfo')
    setStore('SET_EID', '')
    console.log('commit')
  }
}
