import * as axios from 'axios'
import { Message } from 'element-ui'
import cookie from '@/utils/cookies'
import config from '~/config'
import store from '@/store'
import { setStore } from '~/utils/storage'
import { MessageBox } from 'element-ui'

let serverUrl = ''
const createHttp = (token) => {
  const options = {
    timeout: 10 * 1000
  }
  const head = {}
  // 需要全路径才能工作
  if (process.server) {
    if (token) {
      head.token = token
    }
    options.baseURL = `http://${process.env.HOST || 'localhost'}:${process.env.PORT || 3000}/`
  }
  if (process.client) {
    head.token = cookie.getInClient(config.tokenName)
    head.i18nLang = (cookie.getInClient('lang') === 'zh' || !cookie.getInClient('lang')) ? 'zh_CN': cookie.getInClient('lang')
  }
  options.headers = head
  // 获取页面route和发送信息权限的接口
  let permissionsInf = {route: '', url: ''};
  let permissionList = []
  if (process.browser) {
    permissionsInf.route = window.location.pathname;
    permissionList = JSON.parse(localStorage.getItem('permissionList'));
  }
  // 添加请求拦截器
  const http = axios.create(options)
  http.interceptors.request.use(function(config) {
    // 获取接口url
    permissionsInf.url = JSON.parse(JSON.stringify(config.url));
    let url = ''
    if (config.url[0] === '/') {
      url = '/gateway' + config.url
    } else {
      url = '/gateway/' + config.url
    }
    config.url = url
    serverUrl = url
    if (process.server) {
      console.info('request to: ' + serverUrl)
    }
    // 符合条件的route和url，发送通知
    if (permissionList && permissionList.length > 0 && permissionsInf.route && permissionsInf.url) {
      const has = permissionList.find((e) => e.route === permissionsInf.route && e.url === permissionsInf.url)
      if (has) {
        // console.log('调用通知接口', permissionsInf)
        // if (process.browser) {
        //   MessageBox.confirm('确定发送通知？', '', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning',
        //     callback: (action) => {
        //       console.log('调用通知接口', permissionsInf)
        //     }
        //   })
        // }
        // http.get(
        //   '/system/api/website/ip',
        //   {}
        // )
      }
    }
    return config
  }, function(error) {
    // 对请求错误做些什么
    console.warn(error)
    if (process.client) {
      return Promise.reject(error)
    }
  })
  http.interceptors.response.use(function(response) {
    // console.log(response, 'response')
    try {
      if (response.config.data || response.config.params) {
        const d = JSON.parse(response.config.data || response.config.params)
        if (d && d._No_dispose) {
          return Promise.resolve(response.data)
        }
      }
    } catch (err) {
      console.log(err)
    }
    // 对响应数据做点什么
    if (response.data.code === 200) {
      return Promise.resolve(response.data.data || response.data)
    } else {
      if (response.data.code === 321) {
        setStore('loginErrorCount', 2)
        store.dispatch('toggleImageVerification', true)
      }
      console.info('request to: ' + serverUrl)
      console.warn(JSON.stringify(response.data))

      if (response.data.code >= 300 && response.data.code <= 400) {
        if (process.client) { // 客户端请求接口token 过期让他重新登录
          if (response.data.code === 306) {
            window.location.href = '/permission'
            return Promise.reject(response.data.data || response.data)
          } else {
            if (window.location.href.indexOf('/login') === -1 && window.location.href.indexOf('/agreement') === -1) {
              cookie.delInClient(config.tokenName)
              window.location.href = '/login?t=login'
            }
          }
        } else {
          console.info(JSON.stringify(response.data.data || response.data))
        }
      }

      if (process.client) {
        if (response.config.data || response.config.params) {
          let d = {}
          try {
            d = JSON.parse(response.config.data || response.config.params)
          } catch (err) {
            console.log(err)
          }
          if (d.isShowErrTip !== false) {
            // 过滤同时多个接口报token错误 会出现多个提示bug
            const title = localStorage.getItem('___errmsg')
            const time = localStorage.getItem('___errmsgTime')
            const newtime = (new Date()).getTime()
            if (title !== response.data.msg || (newtime - time) > 2000) {
              localStorage.setItem('___errmsg', response.data.msg)
              localStorage.setItem('___errmsgTime', newtime)
              if (response.data.msg && response.data.code !== 601) {
                Message.error(response.data.msg)
              }
            }
            return Promise.reject(response.data.data || response.data)
          } else {
            return Promise.resolve(response.data.data || response.data)
          }
        } else {
          if (response.data.code === 200) {
            return Promise.resolve(response.data.data || response.data)
          } else if (response.data.code >= 300 && response.data.code <= 400) {
            console.log('request login')
            if (process.client) { // 客户端请求接口token 过期让他重新登录
              if (window.location.href.indexOf('/login') === -1 && window.location.href.indexOf('/agreement') === -1) {
                window.location.href = '/login?t=login'
              }
            } else {
              console.info(JSON.stringify(response.data.data || response.data))
            }
          } else {
            const title = localStorage.getItem('___errmsg')
            const time = localStorage.getItem('___errmsgTime')
            const newtime = (new Date()).getTime()
            if (title !== response.data.msg || (newtime - time) > 2000) {
              localStorage.setItem('___errmsg', response.data.msg)
              localStorage.setItem('___errmsgTime', newtime)
              if (response.data.msg) {
                Message.error(response.data.msg)
              }
            }
            return Promise.reject(response.data.data || response.data)
          }
        }
      } else {
        return Promise.resolve(response.data.data || response.data)
      }
    }
  }, function(error) {
    console.log('error', error)
    // 对响应错误做点什么
    Message.error(error.message)
    if (process.client) {
      console.info(JSON.stringify(error.response))
      return Promise.reject(error)
    } else {
      console.info('request to: ' + serverUrl)
      console.info(JSON.stringify(error))
      return Promise.resolve(error.response.data)
    }
  })
  return http
}

export default createHttp
