// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//at.alicdn.com/t/font_2801612_u20wa8bez.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(//at.alicdn.com/t/font_1413662_wwmybr9gcbr.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html{font-size:16px;word-spacing:1px;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;box-sizing:border-box}*,:after,:before{box-sizing:border-box;margin:0}.button--green{border:1px solid #3b8070;border-radius:4px;color:#3b8070;display:inline-block;padding:10px 30px;-webkit-text-decoration:none;text-decoration:none}.button--green:hover{background-color:#3b8070;color:#fff}.button--grey{border:1px solid #35495e;border-radius:4px;color:#35495e;display:inline-block;margin-left:15px;padding:10px 30px;-webkit-text-decoration:none;text-decoration:none}.button--grey:hover{background-color:#35495e;color:#fff}.page_content{height:100vh}.page_container{padding-bottom:40px;padding-top:60px}.app-header{background-color:#fff;box-shadow:0 0 8px 0 rgba(0,0,0,.1);left:0;position:fixed;right:0;top:0;z-index:1000}.app-header.header-border{border:1px solid #ebeef5}.courselist_page{height:100%}.exam_dialog{background:#fff;border-radius:4px}.exam_dialog .el-dialog__header{border-bottom:1px solid #ebeef5;height:60px}.exam_dialog .el-dialog__title{font-size:16px;font-style:normal;font-weight:400}.exam_dialog .exam_dialog_footer{align-items:center;display:flex;height:50px;justify-content:space-between}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
