import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import config from '~/config'
import cookies from '@/utils/cookies'

const createStore = () => {
  return new Vuex.Store({
    state: () => ({
      webInfo: null, // 站点信息
      tokenInfo: '', // token信息
      aboutList: null, // about列表
      lang: cookies.getInClient('lang') || 'zh',
      eid: null,
      navList: { time: 0, list: [] }, // 导航信息
      clientData: { tokenName: config.tokenName, name: '教育系统' }, // 机构信息
      userInfo: {},
      imageVerification: false
    }),
    getters: {
      webInfo: state => state.webInfo,
      lang: state => state.lang
    },
    mutations: mutations,
    actions: actions,
    modules: {

    }
  })
}

export default createStore
